export const styles = {
  streakCard: {
    backgroundColor: "var(--color4)",
    mx: "auto",
    minWidth: "50%",
  },
  counterCard: {
    backgroundColor: "var(--color5)",
  },
  triesCard: {
    backgroundColor: "var(--color3)",
  },
};
