export const styles = {
  card: {
    width: 400,
    mx: "auto",
    height: "auto",
    mt: 5,
    p: 2,
    borderRadius: "sm",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    boxShadow: "md",
    backgroundColor: "lightblue",
  },
  editTeacherSheet: {
    p: 1,
    borderRadius: "4px",
    mt: 1,
    boxShadow: "md",
    maxHeight: "max-content",
    width: "80vw",
    mx: "auto",
    overflow: "auto",
    resize: "horizontal",
  },
  createSkillSheet: {
    maxHeight: "max-content",
    maxWidth: "100%",
    mx: "auto",
    overflow: "auto",
    resize: "horizontal",
  },
  skillSheetCard: {
    maxHeight: "max-content",
    maxWidth: "100%",
    mx: "auto",
    overflow: "auto",
    resize: "horizontal",
    whiteSpace: "pre-line",
  },
  errorText: {
    color: "red",
    fontSize: "1.3em",
  },
};
