export const successMessages = [
  "All Right!",
  "Way to go!",
  "You're doing great!",
  "Amazing!",
  "That was it!!",
  "Holy Guacamole!",
  "Like a pro!",
  "You've got it!",
  "Totally tubular!",
  "Cowabunga dude!",
  "You are in it to win it!",
  "Keep it up!",
  "I'm impressed!",
  "It's all coming together now!",
  "Radical!",
  "It's Beautiful!",
  "You're on fire!",
  "Kicking butt!",
  "So good!",
  "Right on!",
];

export const blunderMessages = [
  "Not quite...",
  "Try again...",
  "You're almost there...",
  "Hmmm....",
  "Keep trying...",
  "Oof...",
  "Once more unto the breach...",
  "Almost...",
  "Keep practicing...",
  "Better do that again...",
  "You're getting closer...",
  "Yeah...",
  "I think you'll get it next time...",
  "Close...",
  "What was that?...",
  "Persistence is the key...",
  "That was a stinker...",
  "Uhhh...",
  "Really?...",
];
