export const styles = {
  sheet: {
    width: "90%",
    mx: "auto",
    backgroundColor: "lightblue",
    mt: 4,
    p: 2,
    borderRadius: "4px",
    boxShadow: "md",
    display: "flex",
    flexDirection: "column",
    whiteSpace: "pre-line",
  },
  container: {
    backgroundColor: "white",
    mx: "auto",
    width: "100%",
    height: "100%",
    p: 3,

    borderRadius: "10px",
  },
  card: {
    backgroundColor: "lightblue",
    width: "98%",
    mx: "auto",
    height: "100%",
    p: 3,
    borderRadius: "10px",
    whiteSpace: "pre-line",
  },
  mobileCard: {
    backgroundColor: "lightblue",
    width: "100%",
    mx: "auto",
    height: "100%",
    p: 1.5,
    borderRadius: "10px",
    whiteSpace: "pre-line",
  },
  tableCard: {
    backgroundColor: "lightblue",
    width: "98%",
    mx: "auto",
    maxHeight: "30%",
    overflow: "auto",
    p: 3,
    borderRadius: "10px",
    whiteSpace: "pre-line",
  },
  selectAvatarCard: {
    p: 1,
    borderRadius: "4px",
    mt: 1,
    boxShadow: "md",
    maxHeight: "max-content",
    maxWidth: "100%",
    mx: "auto",
    overflow: "auto",
    resize: "horizontal",
  },
  editStudent: {
    p: 1,
    borderRadius: "4px",
    mt: 1,
    width: "500px",
    boxShadow: "md",
    maxHeight: "max-content",
    maxWidth: "100%",
    mx: "auto",
    overflow: "auto",
    resize: "horizontal",
  },
  studentDatabaseTable: {
    borderRadius: "4px",
    boxShadow: "lg",
    p: 2,
    mb: 3,
    backgroundColor: "lightblue",
  },
  studentDetailsCardCover: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 800px), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 800px)",
  },
  badgesPoints: {
    backgroundColor: "lightgrey",
    borderRadius: "10px",
    minHeight: "200px",
    maxHeight: "300px",
    maxWidth: "100%",
    minWidth: "100%",
    mx: "auto",
    overflow: "auto",
    resize: "vertical",
  },
  createStudent: { p: 1, mt: 1, borderRadius: "4px", boxShadow: "lg" },
  errorText: {
    color: "red",
    fontSize: "1.3em",
  },
};
